import BaseService from "@core/services/baseService";

export default class UserService extends BaseService{
    getUserData(){
        return JSON.parse(localStorage.getItem('userData'))
    }
    updateUserData(name,email,task = false){
        let userData = this.getUserData();
        userData.fullName = name;
        userData.email = email;
        userData.task_email = task;
        localStorage.setItem('userData',JSON.stringify(userData))
    }
    updateUser(...args){
        return this.axiosIns.patch(this.formConfig.userEndpoint+"/"+args[0].id, ...args)
    }
    updatePassword(...args){
        return this.axiosIns.post(this.formConfig.userChangePasswordEndpoint, ...args)
    }
    acceptTC(...args){
        return this.axiosIns.post(this.formConfig.userAcceptTC, ...args)
    }
    updateTCValue(val){
        let userData = this.getUserData();
        userData.acceptedTC = val;
        localStorage.setItem('userData',JSON.stringify(userData))
    }
}